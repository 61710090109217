import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getEmtpyBoxReport } from "../../services/box/box";
import { Context } from "../../context/app_context";
import EmptyBoxReportTable from "./table";
import Loading from "../loading/loading";

export default function ResultPanelRenderEmtpyBoxReport() {
  const { setLoading, currentTab } = useContext(Context);
  const [reportData, setReportData] = useState();

  const loadReportData = async () => {
    setLoading(true);
    const createResponse = await getEmtpyBoxReport();
    if (createResponse.status === 200) {
      setReportData(createResponse.data);
    } else {
      toast.error("Error loading report");
    }
    setLoading(false);
  };

  useEffect(() => {
    loadReportData();
  }, []);
  if (currentTab === 3) {
    return (
      <>
        <Loading />
        <EmptyBoxReportTable boxes={reportData || []} reload={loadReportData} />
      </>
    );
  } else {
    return <></>;
  }
}
