import styled from "@emotion/styled";
import TableCell, { TableCellProps } from "@mui/material/TableCell";

interface CustomTableCellProps extends TableCellProps {
  background_strip: number;
}

export const TableWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  width: 65px;
  height: 56px;
  margin: auto;
`;
export const DeleteIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  margin: auto;
`;

export const Image = styled.img`
  height: 100%;
`;

export const CustomTableCell = styled(TableCell)<CustomTableCellProps>(
  ({ background_strip }: { background_strip: number }) => ({
    fontSize: "1.1rem",
    padding: "0.5rem",
    border: "none",
    borderBottom: "nopne",
    outline: "none",
    backgroundColor: background_strip ? "#EEE" : undefined,
  })
);
export const CustomTableHeader = styled(TableCell)<CustomTableCellProps>(
  ({ background_strip }: { background_strip: number }) => ({
    fontSize: "0.7rem",
    padding: "0.5rem",
    border: "none",
    borderBottom: "nopne",
    outline: "none",
    backgroundColor: background_strip ? "#EEE" : undefined,
  })
);

export const TableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 1rem;
`;
