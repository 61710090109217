import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Context } from "../../context/app_context";
import { Button, Grid, Typography } from "@mui/material";
import close from "../../assets/close.png";
import InputText from "../inputs/InputText";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changeLocationValidationSchema } from "./changeLocationValidationSchema";
import SwitchInput from "../inputs/switch";
import { toast } from "react-toastify";
import { changeLocation, getBox } from "../../services/box/box";
import { ErrorMessage } from "../error/error";

const ChangeLocationBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 1rem;
`;

const ChangeLocationWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
`;

const CloseImgWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
`;

const CloseImg = styled.img`
  width: 100%;
`;

const CellContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export default function ChangeLocation() {
  const { box, setBox, showCLModal, setLoading, setShowCLModal } =
    useContext(Context);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setFocus,
    setValue,
  } = useForm({ resolver: yupResolver(changeLocationValidationSchema) });

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    const changeLocationResponse = await changeLocation({
      id: box?.id,
      location: { code: data.barcode },
      is_extra_location: data.is_extra_location,
    });

    if (changeLocationResponse.status != 200) {
      toast.error(
        <ErrorMessage testId="change_location_error">
          {changeLocationResponse.data.message}
        </ErrorMessage>
      );
    } else {
      const boxResponse = await getBox({ barcode: box?.id });
      if (boxResponse.status != 200) {
        toast.error(
          <ErrorMessage testId="change_location_error">
            {boxResponse.data.message}
          </ErrorMessage>
        );
      }
      setBox(boxResponse.data);
      toast.success("Location Changed");
    }

    reset({ barcode: "", is_extra_location: false });
    setLoading(false);
    setShowCLModal(false);
  };

  useEffect(() => {
    setValue("is_extra_location", box?.is_extra_location);
  }, [showCLModal]);

  if (showCLModal) {
    return (
      <>
        <ChangeLocationBackground>
          <ChangeLocationWrapper>
            <CloseImgWrapper onClick={() => setShowCLModal(false)}>
              <CloseImg src={close}></CloseImg>
            </CloseImgWrapper>
            <Typography variant="h5">Change Location</Typography>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <CellContent>
                  <Typography variant="body1" fontSize={"12px"}>
                    Type/Scan barcode to change the box location
                  </Typography>
                </CellContent>
              </Grid>
              <Grid item xs={4}>
                <CellContent>
                  <Typography variant="body1" fontSize={"12px"}>
                    Is Extra Location
                  </Typography>
                  <SwitchInput
                    testId="is_extra_location"
                    id="is_extra_location"
                    control={control}
                    errors={errors}
                  />
                </CellContent>
              </Grid>
              <Grid item xs={8}>
                <CellContent>
                  <InputText
                    testId="barcode_popup"
                    sx={{
                      minWidth: "17rem",
                      mt: 1,
                    }}
                    id={"barcode"}
                    label={"Barcode"}
                    control={control}
                    errors={errors}
                    required={false}
                    InputProps={{
                      onKeyDown: (event: KeyboardEvent) => {
                        if (event.key === "Enter") {
                          handleSubmit(onSubmit)();
                        }
                      },
                    }}
                    autoFocus={true}
                  />
                </CellContent>
              </Grid>
              <Grid item xs={4}>
                <CellContent>
                  <Button
                    color="primary"
                    variant="contained"
                    sx={{ borderRadius: 100, color: "white", paddingX: "4rem" }}
                    onClick={handleSubmit(onSubmit)}
                    data-cy="change_location_submit"
                  >
                    Save
                  </Button>
                </CellContent>
              </Grid>
            </Grid>
          </ChangeLocationWrapper>
        </ChangeLocationBackground>
      </>
    );
  } else {
    return <></>;
  }
}
