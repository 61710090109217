import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import deleteIcon from "../../assets/delete.svg";
import { Context } from "../../context/app_context";
import { Button, Typography } from "@mui/material";
import { imageUrl } from "../../common/image_url";
import { BoxItem } from "../../entities/box_items.entity";
import { toast } from "react-toastify";
import { ErrorMessage } from "../error/error";
import { deleteItemFromBox, getBox } from "../../services/box/box";
import { Item } from "../../entities/item.entity";
import { getItem } from "../../services/item/item";
import {
  CustomTableCell,
  CustomTableHeader,
  DeleteIconWrapper,
  Image,
  TableHeader,
  TableWrapper,
} from "./table_styled";

export default function BoxTable() {
  const { box, setCurrentTab, setItem, setBox, setShowADModal, setLoading } =
    useContext(Context);

  const handleSearchItem = async (boxItem: BoxItem) => {
    setLoading(true);
    const searchResponse = await getItem({ barcode: boxItem.item.id });
    if (searchResponse.status != 200) {
      toast.error(
        <ErrorMessage testId="search_item_error">
          {searchResponse.data.message}
        </ErrorMessage>
      );
    } else {
      const item: Item = searchResponse.data;
      await setItem(item);
    }
    setLoading(false);
    setCurrentTab(2);
  };

  const handleDeleteItem = async (boxItem: BoxItem) => {
    if (!boxItem.box.is_extra_location && boxItem.item.quantity_on_hand > 0) {
      toast.warn(
        <ErrorMessage testId="warn_item_inventory_available">
          Item still has available inventory
        </ErrorMessage>
      );
    } else {
      const deleteResponse = await deleteItemFromBox(boxItem);
      if (deleteResponse.status != 200) {
        toast.warn(
          <ErrorMessage testId="error_delete_item">
            {deleteResponse.data.message}
          </ErrorMessage>
        );
      } else {
        const boxResponse = await getBox({ barcode: box?.id });
        if (boxResponse.error) {
          toast.error(
            <ErrorMessage testId="change_location_error">
              {boxResponse.data.message}
            </ErrorMessage>
          );
        }
        setBox(boxResponse.data);
        toast.success("Item deleted from box");
      }
    }

    setLoading(false);
    setShowADModal(false);
  };

  if (box) {
    return (
      <>
        <TableWrapper>
          <TableHeader>
            <Typography variant="h5">Box Contents</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontSize: "1rem",
                borderRadius: 100,
                color: "white",
                paddingX: "2rem",
                gridArea: "1 / 1 / 3 / 3",
              }}
              onClick={() => setShowADModal(true)}
              data-cy="add_item_button"
            >
              Add Item
            </Button>
          </TableHeader>
          <TableContainer component={Paper} sx={{ p: 1 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow sx={{ borderBottom: "1px solid black" }}>
                  <CustomTableHeader background_strip={0} align="center">
                    Actions
                  </CustomTableHeader>

                  <CustomTableHeader
                    background_strip={1}
                    align="center"
                    sx={{ minWidth: "140px", maxWidth: "140px" }}
                  >
                    Description
                  </CustomTableHeader>
                  <CustomTableHeader background_strip={0} align="center">
                    Size
                  </CustomTableHeader>
                  <CustomTableHeader background_strip={1} align="center">
                    QoH
                  </CustomTableHeader>
                  <CustomTableHeader background_strip={0} align="center">
                    QoSO
                  </CustomTableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {box.box_items &&
                  box.box_items
                    .filter((x) => x.item)
                    .map((box_item) => {
                      return (
                        <>
                          <TableRow key={`box_item:${box_item.id}`}>
                            <CustomTableCell
                              background_strip={0}
                              align="center"
                            >
                              <DeleteIconWrapper>
                                <Image
                                  src={deleteIcon}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleDeleteItem(box_item);
                                  }}
                                  data-cy={`delete_item`}
                                />
                              </DeleteIconWrapper>
                            </CustomTableCell>
                            <CustomTableCell
                              background_strip={1}
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleSearchItem(box_item);
                              }}
                            >
                              {`${box_item.item.brand} ${box_item.item.reference} ${box_item.item.color.color}`}
                            </CustomTableCell>
                            <CustomTableCell
                              background_strip={0}
                              align="center"
                            >
                              {box_item.item.size.id}
                            </CustomTableCell>
                            <CustomTableCell
                              background_strip={1}
                              align="center"
                            >
                              {box_item.item.quantity_on_hand}
                            </CustomTableCell>
                            <CustomTableCell
                              background_strip={0}
                              align="center"
                            >
                              {box_item.item.quantity_on_sales_order}
                            </CustomTableCell>
                          </TableRow>
                        </>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      </>
    );
  } else {
    return <></>;
  }
}
