import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";
import { validateRequest } from "../../common/validate_request";

export const createNewBox = async () => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.post(
      `${baseURL}/box/create`,
      { location: { code: "999-9-99" }, is_extra_location: false },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: validateRequest,
      }
    )
  );
  return response;
};
export const getBox = async (data: any) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/box/find/${data.barcode}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const getEmtpyBoxReport = async () => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/box/get_empty_boxes_report`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const changeLocation = async (data: any) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.post(`${baseURL}/box/change_location`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const addItem = async (data: any) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.post(`${baseURL}/box/add_item`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const addBoxPrintQueue = async (boxId: number) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/box/add_to_print_queue/${boxId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const deleteItemFromBox = async (boxItem: any) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.post(`${baseURL}/box/delete_item`, boxItem, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const deleteBox = async (boxId: number) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.delete(`${baseURL}/box/delete_box/${boxId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const deleteBoxFromEmptyReport = async (boxId: number) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.delete(`${baseURL}/box/delete_from_empty_report/${boxId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
