import React, { useContext } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "../../entities/box.entity";
import {
  CustomTableCell,
  CustomTableHeader,
  DeleteIconWrapper,
  Image,
  TableHeader,
  TableWrapper,
} from "../box/table_styled";
import deleteIcon from "../../assets/delete.svg";
import { Context } from "../../context/app_context";
import { deleteBoxFromEmptyReport } from "../../services/box/box";
import { toast } from "react-toastify";

export default function EmptyBoxReportTable({
  boxes,
  reload,
}: {
  boxes: any[];
  reload: () => void;
}) {
  const { setLoading } = useContext(Context);

  const handleDeleteBox = async (boxId: number) => {
    setLoading(true);
    const deleteResponse = await deleteBoxFromEmptyReport(boxId);
    if (deleteResponse.status == 200) {
      toast.success(`Box ${boxId} deleted`);
    } else {
      toast.error(deleteResponse.response.message);
    }
    setLoading(false);
    reload();
  };

  return (
    <>
      <TableWrapper>
        <TableHeader>
          <Typography variant="h5">Empty Boxes Report</Typography>
        </TableHeader>
        <TableContainer component={Paper} sx={{ p: 1 }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ borderBottom: "1px solid black" }}>
                <CustomTableHeader background_strip={0} align="center">
                  Location
                </CustomTableHeader>
                <CustomTableHeader
                  background_strip={1}
                  align="center"
                  sx={{ minWidth: "140px", maxWidth: "140px" }}
                >
                  Box ID
                </CustomTableHeader>

                <CustomTableHeader background_strip={0} align="center">
                  Actions
                </CustomTableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {boxes
                .sort((a: any, b: any) => {
                  return a.location.localeCompare(b.location || ""); 
                })
                .map((box) => {
                  return (
                    <>
                      <TableRow key={`box_item:${box.id}`}>
                        <CustomTableCell
                          background_strip={0}
                          align="center"
                          style={{ fontSize: "32px" }}
                        >
                          {box.location}
                        </CustomTableCell>
                        <CustomTableCell
                          background_strip={1}
                          align="center"
                          style={{ fontSize: "32px" }}
                        >
                          {box.id}
                        </CustomTableCell>

                        <CustomTableCell background_strip={0} align="center">
                          <DeleteIconWrapper>
                            <Image
                              src={deleteIcon}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleDeleteBox(box.id);
                              }}
                              data-cy={`delete_item`}
                            />
                          </DeleteIconWrapper>
                        </CustomTableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </>
  );
}
