import axios from "axios";
import middleware from "../middleware";
import { baseURL } from "../config";
import { validateRequest } from "../../common/validate_request";

export const getSalesOrderDetail = async (order_number: string) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(
      `${baseURL}/sales_order/get_sales_orders_by_number_or_separator/${order_number}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: validateRequest,
      }
    )
  );
  return response;
};
export const getSalesOrderDetailWithExtraLocation = async (order_number: string) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(
      `${baseURL}/sales_order/get_sales_orders_by_number_or_separator_with_extra_location/${order_number}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: validateRequest,
      }
    )
  );
  return response;
};
export const savePickingDetailMessage = async ({
  order_detail_id,
  picking_detail,
}: {
  order_detail_id: number;
  picking_detail: string;
}) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.post(
      `${baseURL}/sales_order/save_order_detail_picking_message/${order_detail_id}`,
      {
        message: picking_detail,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: validateRequest,
      }
    )
  );
  return response;
};
export const asignSeparatorToSalesOrder = async ({
  order_number,
  separator_number,
  color,
}: {
  order_number: string;
  separator_number: string;
  color: string;
}) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.post(
      `${baseURL}/separator/asign_separator`,
      {
        barcode: separator_number,
        sales_order_number: order_number,
        color: color,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        validateStatus: validateRequest,
      }
    )
  );
  return response;
};
export const saveSalesOrderPicked = async ({
  orderId,
}: {
  orderId: number;
}) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/sales_order/update_order_state_picked/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
export const saveSalesOrderPicking = async ({
  orderId,
}: {
  orderId: number;
}) => {
  const token = localStorage.getItem("auth-token");
  const response: any = await middleware(
    axios.get(`${baseURL}/sales_order/update_order_state_picking/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      validateStatus: validateRequest,
    })
  );
  return response;
};
